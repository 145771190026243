<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  :isLoadingSaveButton="loading"
  :disableSubmitButton="loading"
  @cancel="onCancel"
  :hasActions="false"
  customClass="xs-modal"
>
  <div class="assign-members-modal flex-column jc-sb">
    <section class="assign-members-modal__wrapper flex-column jc-sb">
        <div class="assign-members-modal__modal-header flex-row jc-sb ai-c">
            <h2 class="assign-members-modal__title default-text">{{ isEditable ? 'Assign members' : 'Members'}}</h2>
            <div class="assign-members-modal__header-icon cursor-pointer" @click="onCancel">
              <inline-svg :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div class="assign-members-modal__content flex-column">
            <div class="assign-members-modal__wrapper flex-column jc-sb" v-if="isEditable">
              <p class="assign-members-modal__label default-text">Select the phase or tasks of which you’d like to assign</p>
              <div class="assign-members-modal__stages flex-column">
                <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">Check all</el-checkbox>
                <el-checkbox-group v-model="checkedTasks" @change="handleCheckedTasksChange">
                    <el-checkbox v-for="task in tasks" :key="task" :label="task">{{ task }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <el-divider v-if="isEditable"/>
            <div class="assign-members-modal__wrapper flex-column jc-sb">
              <el-form label-position="top" v-if="isEditable">
                <el-form-item label="Email or Workstation">
                    <!-- <el-input v-model="invitePerson"/> -->
                    <el-select v-model="selectedMemberId" filterable @change="onSelectMember(selectedMemberId)">
                      <el-option
                        v-for="(item, index) in userMockData"
                        :key="index"
                        :label="item.email"
                        :value="item.id"
                      />
                    </el-select>
                </el-form-item>
                <ActionItemComponent
                  label="Invite"
                  icon="send-invite-icon.svg"
                  fontColor="#FFA500"
                  :isReverse="true"
                  @call-to-action="invite"/>
              </el-form>
              <div class="assign-members-modal__email-lists-container">
                <div class="assign-members-modal__invited-item flex-row ai-c jc-sb" v-for="(added, index) in userMockDataWithRole" :key="index">
                  <p class="default-text">{{ added.email }} <span>(PENDING)</span></p>
                  <div class="flex-row ai-c">
                    <el-select class="assign-members-modal__role" v-model="added.role" filterable placeholder="Role" @change="onUpdate(scope.row.id, added.role)" :disabled="!isEditable">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                    </el-select>
                    <inline-svg :src="require(`@/assets/icons/trash-icon.svg`)" v-if="isEditable"></inline-svg>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
    <section class="assign-members-modal__actions grid">
      <Button buttonText="save" @handle-click="onSave"/>
    </section>
  </div>
</BaseModal>
</template>
<script>
// import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import { defineComponent } from '@vue/runtime-core';
import { mapGetters } from 'vuex';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import permissionConstant from '@/core/constants/permissions';

export default defineComponent({
  name: 'phase-release-payment-modal',
  emits: ['close', 'on-save'],
  components: {
    BaseModal,
    Button,
    ActionItemComponent,
  },
  props: ['show', 'isEditable'],

  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      checkedTasks: [],
      tasks: ['Task 1', 'Task 2', 'Task 3', 'Task 4'],
      invitePerson: '',

      selectedMemberId: null,
      selectedMemberDetail: null,
      options: [
        {
          value: permissionConstant.EDIT_LABEL,
          label: permissionConstant.EDIT_LABEL,
        },
        {
          value: permissionConstant.VIEW_LABEL,
          label: permissionConstant.VIEW_LABEL,
        },
      ],
    };
  },

  created() {
  },

  computed: {
    ...mapGetters(['userMockData', 'twoUserMockDataWithRole', 'userMockDataWithRole']),
  },

  methods: {
    handleCheckAllChange(val) {
      this.checkedTasks = val ? this.tasks : [];
      this.isIndeterminate = false;
    },
    handleCheckedTasksChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.tasks.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tasks.length;
    },
    onCancel() {
      this.$emit('close');
    },

    onSave() {
      this.$emit('on-save');
    },
    onSelectMember(id) {
      this.selectedMemberDetail = this.userMockData.find((user) => user.id === id);

      this.selectedMemberDetail.role = permissionConstant.VIEW_LABEL;
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.assign-members-modal {
  max-width: 450px;
  min-height: 450px;
  min-height: 590px;

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
  &__content {
  }
  &__wrapper {
    .el-select {
      width: 100%;
    }
  }
  &__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
  &__actions {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  &__role {
    :deep(.el-input__inner) {
      border: none;
      outline: none;
      background: transparent;
      padding: 0;
      width: 70px;
    }
  }
}

:deep(.el-input__inner) {
  border-radius: 100px;
  border: none;
  background-color: rgba(12, 15, 74, 0.05);
}
.el-divider {
  margin: 0;
}

@include media-xs-max-width() {
  .assign-members-modal {
    width: 100%;
    min-height: 400px;
  }
}
</style>
