<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  :isLoadingSaveButton="loading"
  :disableSubmitButton="loading"
  @cancel="onCancel"
  :hasActions="false"
  customClass="xs-modal"
>
  <div class="modal-content flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="modal-content__modal-header flex-row jc-fe ai-c">
            <!-- <h2 class="modal-header__title default-text">Current plan</h2> -->
            <div class="modal-content__header-icon">
              <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div class="modal-content__content flex-column">
          <div class="content">
            <div class="icon">
                <img src="@/assets/icons/question-info.svg"/>
              </div>
              <h3 class="margin-bottom-8 margin-bottom-sm-28">Release payment for Phase {{ stageIndex+1 }}</h3>
              <h4 class="margin-bottom-16 margin-bottom-sm-48">Once approved and released, your tradesperson will receive payment for Phase {{ stageIndex+1 }} and the Phase will be marked as complete.</h4>
              <h5>{{ $filters.formatToCurrency(totalStageCost) }}</h5>
              <p class="margin-bottom-24">By approving and releasing the payment, you agree that all work within this Phase has been completed. Additionally, you agree to our <a href="https://community.iknowa.com/pages/tcs-pp/" target="_blank">Terms and Conditions</a> and <a href="https://community.iknowa.com/pages/tcs-pp/" target="_blank">Privacy Policy</a>.</p>
          </div>
        </div>
    </div>
    <div class="modal-content__actions grid">
      <el-button @click="onSave" class="button active">SAVE</el-button>
      <el-button @click="onCancel" class="button">CANCEL</el-button>
    </div>
  </div>
</BaseModal>
</template>
<script>
// import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import { defineComponent } from '@vue/runtime-core';

import BaseModal from '@/core/components/ui/BaseModal.vue';

export default defineComponent({
  name: 'phase-release-payment-modal',
  emits: ['close', 'approve-release-project-stage-deposit'],
  components: {
    BaseModal,
  },
  props: ['show', 'phase', 'estimate'],

  data() {
    return {
    };
  },

  created() {
  },

  computed: {
    totalStageCost() {
      const { phase } = this;

      if (!phase) {
        return 0;
      }

      return phase.stageCostWithFee;
    },
    stageIndex() {
      const { phase, estimate } = this;

      if (!phase) {
        return 0;
      }
      return estimate.projectStages.indexOf(phase);
    },
  },

  methods: {
    onCancel() {
      this.$emit('close');
    },

    async onSave() {
      this.$emit('approve-release-project-stage-deposit');
    }
  }
});
</script>
<style lang="scss" scoped>
.default-text {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;
    margin: 0;
    color: #0C0F4A;
}
.modal-content {
  max-width: 400px;
  &__title {
    font-size: 24px;
    text-align: center;
  }
  &__input-container {
    gap: .1rem;
  }
  &__input-name {
    font-size: 12px;
    line-height: 22px;
  }
  &__text-description {
    font-size: 12px;
    font-weight: 500;
    color: rgba(12, 15, 74, 0.5);
  }
  &__actions {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
}
.button {
    height: 40px;
    padding: 0 2rem;
    box-sizing: border-box;
    background-color: rgba(12, 15, 74, 0.05);
    border: none;
    border-radius: 7px;
    @extend .default-text;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: rgba(12, 15, 74, 0.5);

    &:hover {
        background-color: rgba(12, 15, 74, 0.05);
        color: rgba(12, 15, 74, 0.5);
    }
}
.active {
    background-color: #FFA500;
    color: #fff;

    &:hover {
        background-color: #FFA500;
        color: #fff;
    }
}
:deep(.el-input__inner) {
  border-radius: 8px;
}
.el-divider {
  margin: 0;
}

.content{
  position: relative;
  margin: 0px auto;
  text-align: center;
  max-width: 80%;

  .icon{
    width: 70px;
    height: 70px;
    display: inline-block;
    text-align: center;

    img{
      width: 100%;
      height: 100%;
    }
  }

  h3{
    font-family: 'Avenir Heavy';
    font-style: normal;
    font-size: 24px;
    line-height: 33px;
    color: #2A59D9;
    word-break: break-word;
  }

  h5{
    font-family: 'Avenir Heavy';
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  p{
    font-family: 'Avenir Heavy';
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #A6A9BE;
    word-break: break-word;
  }

}
</style>
