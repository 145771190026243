import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    name: 'workstation-choose-modal',
    emits: ['submit', 'close'],
    components: {
        BaseModal,
        Button,
        UploadForm
    },
    props: {
        show: Boolean,
        toUserId: {
            type: Number,
            required: true,
        },
        refId: {
            type: Number,
            required: true,
        },
    },
    data: () => {
        return {
            ratingForm: {
                fromUserId: 0,
                toUserId: 0,
                value: null,
                comments: '',
                attachments: [],
                refId: 0,
                type: '',
            },
            form: {
                attachments: [],
            },
            isFormReadyToSubmit: false,
            inputComment: '',
            rateTypes: [
                {
                    id: 1,
                    rate: 'Very Poor'
                },
                {
                    id: 2,
                    rate: 'Poor'
                },
                {
                    id: 3,
                    rate: 'Average'
                },
                {
                    id: 4,
                    rate: 'Good'
                },
                {
                    id: 5,
                    rate: 'Excellent'
                }
            ],
            selectedRating: null,
            apiUrl: process.env.VUE_APP_API_URL,
            isUploading: false,
        };
    },
    watch: {
        selectedRating: {
            immediate: true,
            handler(value) {
                this.isFormReadyToSubmit = (value !== null);
            }
        }
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(PROJECTS_STORE, ['openReviewModal']),
        getUploadUrl() {
            return `${this.apiUrl}/upload`;
        },
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['setOpenReviewModal']),
        cancel() {
            this.$emit('close');
        },
        submitReview() {
            const { authenticatedUser: authUser, toUserId, selectedRating, inputComment, form } = this;
            const fromUserId = authUser.userId;
            const payload = {
                fromUserId,
                toUserId,
                rating: {
                    fromUserId: authUser.userId,
                    toUserId,
                    value: selectedRating,
                    comments: inputComment,
                    attachments: form.attachments,
                    refId: this.refId,
                    type: 'project',
                },
            };
            if (selectedRating) {
                this.isUploading = true;
                this.$emit('submit', payload);
            }
            else {
                this.cancel();
            }
        },
        onUploadSuccess() {
            this.isUploading = false;
        },
        onProgressUpload() {
            this.isUploading = true;
        },
        setSelectedRating(id) {
            this.selectedRating = id;
        }
    }
});
