<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="onCancel"
  :hasActions="false"
  customClass="xs-modal"
>
  <div class="modal-content flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="modal-content__modal-header flex-row jc-sb ai-c">
            <h2 class="modal-header__title default-text">{{screenType === ATTACHMENT_SCREEN ? isTradesperson ? 'Add proof of work' : 'Proof of work' : 'Phase # notes'}}</h2>
            <div class="modal-content__header-icon">
              <inline-svg class="cursor-pointer" @click="onCancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div v-if="isTradesperson">
          <div class="modal-content__upload" v-if="!loadingAttachments">
            <UploadForm
              v-if="screenType === ATTACHMENT_SCREEN"
              :uploadUrl="getUploadUrl"
              :hasCustomFnRemoveFile="true"
              :record="form"
              :attachmentSources="combinedAttachments"
              :useCustomUploadIcon="true"
              listType="picture"
              :allowMultiple="true"
              @on-upload-success="onUploadSuccess"
              @on-progress-upload="onProgressUpload"
              @custom-fn-remove-file="handleRemoveFile"
              />
            <div class="flex-column ai-fs" v-else>
              <p class="modal-content__secondary-header default-text">Notes</p>
              <el-input type="textarea" v-model="form.description" placeholder="Enter Notes" :rows="7"></el-input>
            </div>
          </div>
          <div class="attachment-list margin-top-24" v-if="loadingAttachments">
            <i class="common-icon-loading"></i>
          </div>
        </div>

        <div v-if="isProjectOwner">
          <GridAttachmentsView
            v-if="isProjectOwner"
            :attachmentUrls="attachmentUrls"
            :documentAttachments="documentAttachments"
            :imageAttachments="imageAttachments"
            :loadingAttachments="loadingAttachments"
            @is-image-attachment="isImageAttachment($event)"
            @is-pdf="isPdfFile($event)"/>

          <!-- <div v-if="phase.projectStageAttachments && project.projectStageAttachments.length">
            <el-image fit="cover" :src="phase.projectAttachmentsThumbs[0].src">
              <template v-slot:error>
                <div class="image-slot">
                  <img src="@/assets/images/po-project-empty-placeholder.jpg" width="100%"/>
                </div>
              </template>
            </el-image>
          </div>
          <el-image v-else :src="require('@/assets/images/po-project-empty-placeholder.jpg')" fit="contain"></el-image> -->
          <div class="flex-column ai-fs" v-else>
            <p class="modal-content__secondary-header default-text">Notes</p>
            <el-input type="textarea" :disabled="true" :readonly="true" v-model="form.description" placeholder="" :rows="7"></el-input>
          </div>
        </div>
    </div>
    <div class="modal-content__actions grid" v-if="isTradesperson">
      <el-button @click="onSave" :loading="isUploading" :disabled="isUploading" class="button active">SAVE</el-button>
      <el-button @click="onCancel" :disabled="isUploading" class="button">CANCEL</el-button>
    </div>

    <div class="modal-content__actions grid" v-if="isProjectOwner">
      <el-button @click="onClose" class="button active">Done</el-button>
    </div>
  </div>
</BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import GridAttachmentsView from '@/core/components/common/attachments/GridAttachmentsView.vue';
import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { ImageFileTypes } from '@/core/helpers/file.helper';
import { ATTACHMENT_SCREEN } from '@/modules/project-schedule/constants';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { PROJECT_STAGES_CACHE } from '@/store/modules/attachment-cache/constants';

export default defineComponent({
  name: 'phase-attachment-modal',
  emits: ['close', 'handleRemoveFile', 'update-attachments-state'],
  components: {
    BaseModal,
    UploadForm,
    GridAttachmentsView
  },
  props: ['show', 'phase', 'estimate', 'screenType'],

  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      isUploading: false,
      form: {
        attachments: [],
        description: ''
      },

      loadingAttachments: false,
      attachmentUrls: [],
      imageAttachments: [],
      documentAttachments: [],
      combinedAttachments: [],

      ATTACHMENT_SCREEN
    };
  },

  created() {
    this.form.attachments = [];
  },

  mounted() {
    this.loadAttachments();
  },

  computed: {
    ...mapGetters(['isProjectOwner', 'isTradesperson']),

    hasAttachments() {
      return this.form.attachments.length > 0;
    },

    getUploadUrl() {
      return `${this.apiUrl}/project-quotes/${this.estimate.id}/stages/${this.phase.id}/attachment?isProof=1`;
    },

    previewImages() {
      return this.imageAttachments.map((attachment) => attachment.src);
    },
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    async loadAttachments(recent = false) {
      const { projectStageAttachments } = this.phase;
      let attachments = projectStageAttachments.filter((attachment) => attachment.isProof);

      if (recent) {
        attachments = this.form.attachments;
      }

      this.loadingAttachments = true;

      await this.getAttachments({
        name: PROJECT_STAGES_CACHE,
        attachments,
        includeUrl: true,
      })
        .then((values) => {
          const newValues = values.filter(Boolean);

          if (newValues.length) {
            this.attachmentUrls = values;

            this.imageAttachments = newValues.filter((item) => {
              return this.isImageAttachment(item);
            });

            this.documentAttachments = newValues.filter((item) => {
              return !this.isImageAttachment(item);
            });
          }

          this.combinedAttachments = [
            ...this.imageAttachments,
            ...this.documentAttachments
          ];
        })
        .catch(() => {})
        .finally(() => { this.loadingAttachments = false; });
    },

    isImageAttachment(file) {
      let { attachment: filename } = file;
      const { name } = file;

      if (!filename) { filename = name; }
      if (!filename) return false;

      const extension = filename.split('.')[filename.split('.').length - 1];

      return ImageFileTypes.includes(extension.toLowerCase());
    },

    isPdfFile(type) {
      let bool = type === 'application/pdf';

      if (type && type.indexOf('.pdf') > -1) {
        bool = true;
      }

      return bool;
    },

    onCancel() {
      this.form.attachments = [];
      if (!this.isUploading) {
        this.$emit('close');
      }
    },

    onClose() {
      this.form.attachments = [];
      this.$emit('close');
    },

    onSave() {
      this.$emit('close');
    },

    async onUploadSuccess() {
      await this.loadAttachments(true);

      const updatedAttachments = this.combinedAttachments.map((attachment) => {
        return {
          ...attachment,
          isProof: true,
          projectStageId: this.phase.id
        };
      });

      this.$emit('update-attachments-state', { attachments: updatedAttachments, projectStageId: this.phase.id });
      this.isUploading = false;
    },

    onProgressUpload() {
      this.isUploading = true;
    },

    handleRemoveFile(attachment) {
      this.$emit('handleRemoveFile', attachment);
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;
    margin: 0;
    color: #0C0F4A;
}
.modal-content {
  width: 400px;
  min-height: 590px;

  &__title {
    font-size: 24px;
    text-align: center;
  }
  &__input-container {
    gap: .1rem;
  }
  &__input-name {
    font-size: 12px;
    line-height: 22px;
  }
  &__text-description {
    font-size: 12px;
    font-weight: 500;
    color: rgba(12, 15, 74, 0.5);
  }
  &__actions {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }

  &__wrapper {
    width: 100%;
  }

  &__secondary-header {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
  }

  :deep(.el-textarea__inner) {
    background: rgba(12, 15, 74, 0.05);
    border:none;
    border-radius: 8px;
  }

  &__attachment-list {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-gap: 1rem;
  }
  &__image {
    width: 100% !important;
    border-radius: 8px;
  }
}
.button {
    height: 40px;
    padding: 0 2rem;
    box-sizing: border-box;
    background-color: rgba(12, 15, 74, 0.05);
    border: none;
    border-radius: 7px;
    @extend .default-text;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: rgba(12, 15, 74, 0.5);

    &:hover {
        background-color: rgba(12, 15, 74, 0.05);
        color: rgba(12, 15, 74, 0.5);
    }
}
.active {
    background-color: #FFA500;
    color: #fff;

    &:hover {
        background-color: #FFA500;
        color: #fff;
    }
}
:deep(.el-input__inner) {
  border-radius: 8px;
}
.el-divider {
  margin: 0;
}

  .el-image {
    width: 100px;
    height: 100px;

    ::v-deep {
      img {
        object-fit: cover;
      }
    }
  }

@include media-xs-max-width() {
  .modal-content {
    width: 100%;
    min-height: 400px;
  }
}
</style>
