import { ArrowDown, ArrowUp, SuccessFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import IconStatusComponent from '@/core/components/common/status/IconStatusComponent.vue';
import FooterCardActionsSlot from '@/core/components/slots/FooterCardActionsSlot.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Counter from '@/core/components/ui/Counter.vue';
import ThumbnailCounter from '@/core/components/ui/ThumbnailCounter.vue';
import { ProjectQuoteStatusEnum, ProjectStageStatusEnum, ProjectStageTypeEnum } from '@/core/packages/shared-library';
// import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { ATTACHMENT_SCREEN, NOTES_SCREEN } from '@/modules/project-schedule/constants';
import PhaseAssignMembersModal from '@/modules/project-schedule/modals/PhaseAssignMembersModal.vue';
import PhaseProofOfWorkModal from '@/modules/project-schedule/modals/PhaseProofOfWorkModal.vue';
import PhaseReleasePaymentModal from '@/modules/project-schedule/modals/PhaseReleasePaymentModal.vue';
// import PhaseButtonStatusComponent from '@/core/components/common/status/PhaseButtonStatusComponent.vue';
import ProjectStageInvoiceModal from '@/modules/project-stages-tasks/modals/ProjectStageInvoiceModal.vue';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
import { PROJECT_STAGES_TASKS_STORE } from '@/store/modules/project-quotes/submodules/project-stages-tasks';
import { PROJECT_STAGE_DEPOSITS_STORE } from '@/store/modules/project-stage-deposits';
import { PROJECT_STAGES_STORE } from '@/store/modules/project-stages';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        ArrowDown,
        ArrowUp,
        IconStatusComponent,
        // PhaseButtonStatusComponent,
        PhaseProofOfWorkModal,
        PhaseReleasePaymentModal,
        SuccessFilled,
        ProjectStageInvoiceModal,
        FooterCardActionsSlot,
        ActionItemComponent,
        // UserThumbnail,
        PhaseAssignMembersModal,
        ThumbnailCounter,
        Counter
    },
    emit: ['view-project-stage-invoice', 'update-project-stage-status', 'clicked-phase-index'],
    props: ['estimate', 'isShowAllData'],
    data() {
        return {
            ProjectStageTypeEnum,
            currentPhase: null,
            showAttachmentModal: false,
            showReleasePaymentModal: false,
            showAssignMembersModal: false,
            verifyingStage: 0,
            attachments: [],
            projectStages: [],
            loadingProjectStageApproveReleaseDeposit: false,
            isDoneTask: true,
            ProjectStageStatusEnum,
            openProjectStageInvoiceModal: false,
            loadingProjectStageInvoice: false,
            isProjectReady: false,
            screenType: '',
            ATTACHMENT_SCREEN,
            NOTES_SCREEN
        };
    },
    watch: {
        attachments: {
            immediate: true,
            handler() {
                this.initialize();
            }
        },
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner', 'userMockData']),
        ...mapGetters(PROJECTS_STORE, [
            'currentSelectedProject',
            'isProjectReadyToWork'
        ]),
        showThumbnailCounter() {
            return this.userMockData && this.userMockData.length > 0;
        },
        // Note this is just a temporary logic for isEditable value
        isEditable() {
            return this.isTradesperson;
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(USERS_STORE, ['getUserCustomerAccount', 'getUserCustomerAccountDetails', 'getUserRatingsPageList', 'createUserRating', 'getUserRatingSummary']),
        ...mapActions(PROJECT_STAGES_TASKS_STORE, [
            'updateProjectStage',
            'updateProjectStageStatus',
            'updateProjectStageTaskStatus',
            'checkAllProjectStagesIfCompleted'
        ]),
        ...mapActions(PROJECT_STAGE_DEPOSITS_STORE, [
            'createProjectStageDeposit',
            'approveReleaseProjectStageDeposit',
            'createAllProjectStageDeposit'
        ]),
        ...mapActions(PROJECT_STAGES_STORE, ['getProjectStage', 'createProjectStageInvoice']),
        ...mapActions(PROJECT_QUOTES_STORE, ['getTotalStageCost', 'calculateServiceFee']),
        ...mapActions(PROJECT_STAGES_TASKS_STORE, ['deleteProjectStageAttachment']),
        async initialize() {
            await this.setProjectStageServiceFee();
            this.projectStages = this.estimate.projectStages.map((phase) => ({ ...phase, isCheckedAllTask: this.isAllTaskChecked(phase?.projectStageTasks) }));
            this.isProjectReady = this.currentSelectedProject?.projectJobStatus?.id >= ProjectQuoteStatusEnum.QUOTE_REJECTED;
        },
        getCombinedMembers(tasks) {
            const members = [];
            tasks.forEach((task) => {
                const { projectStageTaskMembers } = task;
                if (projectStageTaskMembers.length) {
                    projectStageTaskMembers.forEach((member) => {
                        const hasExist = members.find((item) => item.id === member.id);
                        if (!hasExist) {
                            members.push(member);
                        }
                    });
                }
            });
            return members;
        },
        isAllTaskChecked(tasks) {
            return !(tasks.find((task) => !task.done));
        },
        isPhaseReadyToWork(phase) {
            return phase.projectStageStatus?.id === ProjectStageStatusEnum.IN_PROGRESS || phase.projectStageStatus.id === ProjectStageStatusEnum.IN_PROGRESS_POKED;
        },
        isPhasePoked(phase) {
            return phase.projectStageStatus?.id === ProjectStageStatusEnum.IN_PROGRESS_POKED;
        },
        async setProjectStageServiceFee() {
            const { estimate: projectQuote } = this;
            if (projectQuote.projectStages) {
                const getProjectStageServiceFeePromises = [];
                projectQuote.projectStages.map((projectStage, i) => {
                    getProjectStageServiceFeePromises.push(this.getProjectStageServiceFee(i));
                    return projectStage;
                });
                const getProjectStageServiceFeeResponses = await Promise.all(getProjectStageServiceFeePromises);
                if (getProjectStageServiceFeeResponses) {
                    projectQuote.projectStages = projectQuote.projectStages.map((projectStage, i) => {
                        const serviceFee = getProjectStageServiceFeeResponses[i];
                        const projectStageWithServiceFee = {
                            ...projectStage,
                            serviceFee: getProjectStageServiceFeeResponses[i],
                            stageCost: (projectStage.stageCost || projectStage.subTotal),
                            stageCostWithFee: serviceFee + (projectStage.stageCost || projectStage.subTotal)
                        };
                        return projectStageWithServiceFee;
                    });
                }
            }
        },
        async getProjectStageServiceFee(stageIndex) {
            const { estimate: projectQuote } = this;
            const totalStageCost = await this.getTotalStageCost(projectQuote.projectStages);
            const totalServiceFee = await this.calculateServiceFee({ projectId: this.selectedProject?.id, projectStages: projectQuote.projectStages });
            const total = projectQuote.projectStages[stageIndex].stageCost || projectQuote.projectStages[stageIndex].subTotal;
            const stageCostPercentage = total / totalStageCost;
            const stageServiceFee = totalServiceFee * stageCostPercentage;
            return parseFloat(stageServiceFee.toFixed(2));
        },
        async downloadProjectStageInvoice(invoiceInformation) {
            this.loadingProjectStageInvoice = true;
            await this.createProjectStageInvoice({
                projectQuoteId: this.estimate.id,
                projectStageId: this.currentPhase.id,
                invoiceInformation: { ...invoiceInformation }
            })
                .then((response) => {
                if (response.url) {
                    window.open(response.url, '_blank');
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error Downloading Project Stage Invoice',
                    message: 'Error occurred when downloading stage invoice at the moment. Please try again.'
                });
            })
                .finally(() => {
                this.loadingProjectStageInvoice = false;
            });
        },
        checkIfNotStarted(status) {
            return status === ProjectStageStatusEnum.NOT_STARTED || status === ProjectStageStatusEnum.NOT_STARTED_POKED;
        },
        checkIfInProgress(status) {
            return status === ProjectStageStatusEnum.IN_PROGRESS || status === ProjectStageStatusEnum.IN_PROGRESS_POKED;
        },
        checkIfCompleted(status) {
            return status === ProjectStageStatusEnum.STAGE_COMPLETE;
        },
        checkReadyAttachments(status) {
            return status === ProjectStageStatusEnum.IN_PROGRESS_POKED || status === ProjectStageStatusEnum.STAGE_COMPLETE || status === ProjectStageStatusEnum.PAYMENT_IN_PROCESS;
        },
        openPhaseAttachmentModal(phase, screenType) {
            this.currentPhase = phase;
            this.screenType = screenType;
            this.showAttachmentModal = true;
        },
        openAssignMembersModal(phase) {
            this.currentPhase = phase;
            this.showAssignMembersModal = true;
        },
        closeAssignMembersModal() {
            this.currentPhase = null;
            this.showAssignMembersModal = false;
        },
        closePhaseAttachmentModal() {
            this.currentPhase = null;
            this.showAttachmentModal = false;
        },
        overrideCurrentPhaseAttachmentsState(event) {
            const attachments = [];
            event.attachments.map((attachment) => {
                attachment.projectStageId = event.projectStageId;
                if (attachment.isProof) {
                    attachments.push(attachment);
                }
                return attachment;
            });
            this.projectStages = this.estimate.projectStages.map((phase) => {
                let files = phase.projectStageAttachments || [];
                if (phase.id === event.projectStageId) {
                    files = attachments;
                }
                return { ...phase, projectStageAttachments: files };
            });
        },
        proofOfWorkCounter(attachments) {
            return attachments.filter((attachment) => attachment.isProof === true).length;
        },
        phaseAttachmentCounter(attachments) {
            return attachments.filter((attachment) => attachment.isProof === false).length;
        },
        openReleasePaymentModal(phase) {
            this.currentPhase = phase;
            this.showReleasePaymentModal = true;
        },
        closeReleasePaymentModal() {
            this.showReleasePaymentModal = false;
            this.currentPhase = null;
        },
        onPayDeposit() {
            const { $route } = this;
            const { projectRefId } = $route.params;
            this.$router.push(`/projects/${projectRefId}/project-details/overview`);
        },
        async onReleasePayment() {
            try {
                this.loadingProjectStageApproveReleaseDeposit = true;
                const { projectQuoteCreator } = this.estimate;
                const userCustomerAccount = await this.getUserCustomerAccount(projectQuoteCreator.id);
                const stageServiceFee = parseFloat(this.currentPhase.serviceFee.toFixed(2));
                const formData = {
                    stage: this.currentPhase,
                    userCustomerAccount,
                    stageServiceFee,
                    projectId: this.currentSelectedProject?.id,
                    projectAccount: this.currentSelectedProject.projectAccount
                };
                await this.approveReleaseProjectStageDeposit(formData)
                    .then(async () => {
                    this.$notify.success({
                        message: 'Successfully released the payment in Stage',
                    });
                    await this.executeUpdateProjectStageCompleted(this.currentPhase)
                        .then(() => {
                        this.showReleasePaymentModal = false;
                    })
                        .catch(() => {
                        this.$notify.error({
                            title: 'Error update the status',
                            message: 'An error occurred. Please try again.'
                        });
                    });
                })
                    .catch(() => {
                    this.$notify.error({
                        title: 'Error Approve Release Deposit',
                        message: 'Unable to release of funds to TP/Your account has no sufficient funds at this time. Please try again.'
                    });
                });
                this.loadingProjectStageApproveReleaseDeposit = false;
            }
            catch (error) {
                this.$notify.error({
                    title: 'Error Approve Release Deposit',
                    message: 'Failed, Please refresh the page and try again.'
                });
                this.loadingProjectStageApproveReleaseDeposit = false;
            }
        },
        async executeUpdateProjectStageCompleted(projectStage) {
            const projectQuoteId = this.estimate.id;
            const projectStageId = projectStage.id;
            const formData = {
                projectStageStatusId: ProjectStageStatusEnum.STAGE_COMPLETE,
            };
            this.updateProjectStageStatus({ projectQuoteId, projectStageId, formData })
                .then((response) => {
                if (response) {
                    const { projectStageStatus } = response;
                    this.projectStages[this.projectStages.indexOf(projectStage)] = { ...this.projectStages[this.projectStages.indexOf(projectStage)], projectStageStatus };
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error Updating Stage Status',
                    message: 'Error occurred on updating stage status at the moment. Please try again.',
                });
            });
        },
        getDays(phase) {
            const totalDays = phase.projectStageTasks.reduce((sum, { duration }) => sum + duration, 0);
            if (totalDays > 0) {
                return totalDays;
            }
            return phase.totalDuration || 0;
        },
        getTotalCost(phase) {
            if (phase.stageType === 'materials') {
                return phase.subTotal;
            }
            const totalEarning = phase.projectStageTasks.reduce((sum, { total }) => sum + total, 0);
            if (totalEarning > 0) {
                return totalEarning;
            }
            return phase.stageCost;
        },
        isStageButtonDisabled(stage) {
            let disabled = false;
            const { projectStageAttachments } = stage;
            const { attachments: recentAttachments } = this;
            switch (stage.projectStageStatus.id) {
                case ProjectStageStatusEnum.NOT_STARTED_POKED:
                    if (this.isTradesperson) {
                        disabled = true;
                    }
                    break;
                case ProjectStageStatusEnum.IN_PROGRESS:
                    // if (!projectStageAttachments || !projectStageAttachments.length) {
                    //   disabled = true;
                    // }
                    if (recentAttachments.length && !stage.projectStageTasks.length) {
                        disabled = false;
                    }
                    if (!stage.projectStageTasks.length && projectStageAttachments.length) {
                        disabled = false;
                    }
                    if (stage.projectStageTasks.length) {
                        const isDone = this.checkIfAllProjectTasksIsDone(stage.projectStageTasks);
                        if (!isDone) {
                            disabled = true;
                        }
                        else {
                            disabled = false;
                        }
                    }
                    break;
                case ProjectStageStatusEnum.IN_PROGRESS_POKED:
                    // attachments are now optional
                    // if (!projectStageAttachments || !projectStageAttachments.length) {
                    //   disabled = true;
                    // } else
                    if (this.isTradesperson) {
                        disabled = true;
                    }
                    break;
                case ProjectStageStatusEnum.STAGE_COMPLETE:
                    disabled = false;
                    break;
                case ProjectStageStatusEnum.PAYMENT_IN_PROCESS:
                    disabled = true;
                    break;
                default:
            }
            return disabled;
        },
        checkIfAllProjectTasksIsDone(tasks) {
            let isDone = false;
            if (tasks && tasks.length) {
                isDone = tasks.every(((task) => task.done === true));
            }
            return isDone;
        },
        async viewProjectStageInvoice(phase) {
            this.openProjectStageInvoiceModal = true;
            this.currentPhase = phase;
        },
        async executeUpdateProjectStageStatus(stage, index, recentAttachments) {
            const newProjectStageStatusId = this.getNewProjectStageStatus(stage, recentAttachments);
            const { projectStageStatus } = stage;
            this.verifyingStage = 1;
            if (projectStageStatus.id === ProjectStageStatusEnum.STAGE_COMPLETE) {
                this.viewProjectStageInvoice(stage);
            }
            else if (newProjectStageStatusId) {
                const projectQuoteId = this.estimate?.id;
                const projectStageId = stage.id;
                const formData = {
                    projectStageStatusId: newProjectStageStatusId,
                };
                await this.updateProjectStageStatus({ projectQuoteId, projectStageId, formData })
                    .then((response) => {
                    if (response) {
                        const { projectStageStatus: status } = response;
                        this.projectStages[this.projectStages.indexOf(stage)] = { ...this.projectStages[this.projectStages.indexOf(stage)], status };
                        stage.projectStageStatus = response.projectStageStatus;
                        this.projectStages[index].projectStageStatus = response.projectStageStatus;
                        this.$notify.success({
                            title: 'Project Stage Request Successful.',
                            message: 'You successfully submitted project phase action.'
                        });
                    }
                })
                    .catch(() => {
                    this.$notify.error({
                        title: 'Error Updating Stage Status',
                        message: 'Error occurred on updating stage status at the moment. Please try again.',
                    });
                });
            }
            else if (this.isProjectOwner
                && (stage?.projectStageStatus?.id === ProjectStageStatusEnum.NOT_STARTED
                    || stage?.projectStageStatus?.id === ProjectStageStatusEnum.NOT_STARTED_POKED)) {
                this.onPayDeposit();
            }
            else if (this.isProjectOwner && (stage.projectStageStatus.id === ProjectStageStatusEnum.IN_PROGRESS
                || stage.projectStageStatus?.id === ProjectStageStatusEnum.IN_PROGRESS_POKED)) {
                this.initiateApproveReleaseProjectStage(stage);
            }
            this.verifyingStage = 0;
        },
        getNewProjectStageStatus(stage, recentAttachments) {
            if (!stage) {
                this.$notify.error({
                    message: 'No stages available.'
                });
                return false;
                // no stages defined
            }
            let newProjectStageStatusId = null;
            switch (stage.projectStageStatus?.id) {
                case ProjectStageStatusEnum.NOT_STARTED:
                    if (this.isTradesperson) {
                        newProjectStageStatusId = ProjectStageStatusEnum.NOT_STARTED_POKED;
                    }
                    break;
                case ProjectStageStatusEnum.IN_PROGRESS:
                    if ((stage && stage.projectStageAttachments) || recentAttachments.length) {
                        if (this.isTradesperson) {
                            newProjectStageStatusId = ProjectStageStatusEnum.IN_PROGRESS_POKED;
                        }
                    }
                    break;
                default:
            }
            return newProjectStageStatusId;
        },
        async onCompleteTask({ projectStageTaskStatus, projectStageTaskId, projectStageId }) {
            const projectQuoteId = this.estimate.id;
            this.updateProjectStageTaskStatus({
                projectQuoteId, projectStageId, projectStageTaskId, projectStageTaskStatus
            })
                .then(() => {
                this.projectStages = this.estimate.projectStages.map((phase) => ({ ...phase, isCheckedAllTask: this.isAllTaskChecked(phase?.projectStageTasks) }));
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error Updating Stage Task/Materials',
                    message: 'Error occurred when updating stage task/materials at the moment. Please try again.'
                });
            });
        },
        async onCompleteAllTask(phase) {
            const projectQuoteId = this.estimate.id;
            let projectStageTaskStatus;
            let projectStageTaskId;
            let projectStageId;
            const getProjectStageTasksPromises = [];
            for (let i = 0; i < phase.projectStageTasks.length; i++) {
                projectStageId = phase.id;
                projectStageTaskId = phase?.projectStageTasks[i].id;
                projectStageTaskStatus = phase?.isCheckedAllTask;
                getProjectStageTasksPromises.push(this.updateProjectStageTaskStatus({
                    projectQuoteId, projectStageId, projectStageTaskId, projectStageTaskStatus
                }));
            }
            await Promise.all(getProjectStageTasksPromises)
                .then((responses) => {
                responses.forEach((response) => {
                    phase.projectStageTasks.map((task) => {
                        task.done = response?.done;
                        return task;
                    });
                });
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error Updating Stage Task/Materials',
                    message: 'Error occurred when updating stage task/materials at the moment. Please try again.'
                });
            });
        },
        isProjectStageNotStarted(projectStageStatusId) {
            return projectStageStatusId === ProjectStageStatusEnum.NOT_STARTED || projectStageStatusId === ProjectStageStatusEnum.NOT_STARTED_POKED;
        },
        initiateApproveReleaseProjectStage(stage) {
            this.openReleasePaymentModal(stage);
        },
        handleRemoveFile(attachment) {
            const component = this;
            const { id: projectQuoteId } = component.estimate;
            const { id: projectStageId } = component.currentPhase;
            const { id: projectStageAttachmentId } = attachment;
            this.deleteProjectStageAttachment({
                projectQuoteId,
                projectStageId,
                projectStageAttachmentId
            })
                .then(() => {
                const { projectStageAttachments } = this.currentPhase;
                let newList = [];
                if (projectStageAttachments) {
                    newList = projectStageAttachments.filter((item) => item.id !== attachment.id);
                    this.currentPhase.projectStageattachments = newList;
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Delete Attachment Error',
                    message: 'Error in deleting attachment. Please try again.',
                });
            });
        },
        viewPhaseAttachments(phaseIndex) {
            this.$emit('clicked-phase-index', phaseIndex);
        },
    }
});
